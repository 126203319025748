import React from "react";
import Footer from "./Footer";
import { ToastContainer } from "react-toastify";

class Basepages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { currentLocation } = this.props;
    return (
      <div
        className={`basepage-main-container ${currentLocation}_wrapper_container`}
      >
        <div
          className={
            currentLocation === "terms_conditions"
              ? "min-height-90vh"
              : "centerofthescreen"
          }
        >
          {this.props.children}
        </div>
        <div className="footer footer-containerr basepage-footer-containerr">
          <Footer color="white" />
        </div>
        <ToastContainer />
      </div>
    );
  }
}
export default Basepages;
