import React, { Component } from "react";
import moment from "moment";
import "./footer.css";
class Footer extends Component {
  render() {
    const year = moment(new Date()).format("YYYY");
    return (
      <div className="row justify-content-center shrinkFooter">
        <div className="copyright-text text-center mt-3 footer">
          Copyright © {year} PermianChain Technologies, Inc.
        </div>
      </div>
    );
  }
}
export default Footer;
